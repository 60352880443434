import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import Layout from '../../layouts/WallisLayout';
import Banner from '../../components/wallis/Banner/Banner';
import JobSearch from '../../components/wallis/JobSearch/JobSearch';
import wallisTransportLogo from '../../images/wallis-transport.png';
import transportBanner from '../../images/Transport-Photo.jpg';
import { assignMultipleLocations } from '../../helpers/locations';

const SearchPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	function filterByBrand(edges: any[], brandToFilter: string) {
		return edges.filter(
			(page) => page.node.pageContext.brand === brandToFilter
		);
	}

	data.allSitePage.edges = filterByBrand(
		data.allSitePage.edges,
		'Wallis Co. Transport'
	);

	data.allSitePage.edges = assignMultipleLocations(data.allSitePage.edges);

	return (
		<Layout
			href="https://www.wallisco.com/"
			headerType="light"
			logo={wallisTransportLogo}
			title="Wallis Transport | Employment Opportunities"
			desc="Wallis Transport | Employment Opportunities"
		>
			<Banner
				bgImage={transportBanner}
				text="The Wallis Transport Driver is responsible for safe driving during delivery of fuel product, to include accountability for loading and unloading of correct fuel products at the right place and time, before and after tank inventories, testing tanks for water, operating a safe work environment, reporting maintenance issues of vehicles, and various other duties."
			/>
			<section className={'bg-[#B6E2EC]'}>
				<div className={'container mx-auto py-16 px-6'}>
					<p className="mx-auto max-w-[820px] text-center font-hind text-xl font-medium text-[#1B5AA1]">
						Because we’re family owned, we operate family-friendly
						locations. We are an equal opportunity employer and
						operate a drug-free work environment. We are focused on
						the safety of our team members and our customers —
						adhering to the required environmental guideline and
						emphasizing safety in the workplace every day. Our
						schedules are flexible, and the pay and benefits are
						competitive.
					</p>
				</div>
			</section>
			<JobSearch data={data} />
		</Layout>
	);
};
export default SearchPage;
